export default {
  state: {
    isDarkMode: false,

    language: null,
    showAddCustomerModal: false,
    showAddFamilyMemberModal:false,
    showAddSearchFamilyMemberModal:false,
    showAddCompanyModal: false,
    showAddPrivateModal: false,
    showEditPrivateEmailModal: false,
    showEditCompanyEmailModal: false,
    emailIdToEdit: null,
    showAddUserModal: false,
    teamTaxesToEdit: null,
    showAddTeamModal: false,
    showAddTeamTaxModal: false,
    deleteModal: {
      display: false,
      message: "",
      action: null,
      id: null,
    },
    showAddServiceModal: false,
    showAddPermissionModal: false,
    showAddRoleModal: false,
    usersLoading: false,
    teamsLoading: false,
    teamTaxesLoading: false,
    customersLoading: false,
    isAddingCustomer: false,
    toast: {
      display: false,
      type: null,
      title: null,
      message: null,
    },
    isEdit: false,
    isProfileEdit: false,
    userToEdit: {},
    companyIsEdit: false,
    companyToEdit: {},
    privateIsEdit: false,
    privateToEdit: {},
    customerToAddMember: {},
    teamIsEdit: false,
    teamToEdit: {},
    teamTaxToEdit: {},
    showAddCustomerServicesModal: false,

    showAddActivityModal: false,

    showAddPendencyModal: false,

    showFilesEditModal: false,
    filesEditMode: null,
    filesToEdit: [],
    filesToEditId: null,

    pendencyForReply: false,

    isUserReplyingToPendency: false,

    pendencyReplyPolicyId: null,
    pendencyReplyServiceId: null,
    pendencyReplyAgent: null,

    isPendecyEditing: null,
    isReplyEditing: null,
    pendencyEditData: null,
    replyEditData: null,
    replyEditPolicyId: null,

    showAddNotesModal: false,
    showAddCallsModal: false,

    isNoteEditing: null, 
    editingNoteData: null,

    isCallEditing: null, 
    editingCallData: null,

    showAddPolicyModal: false,
    showPolicyFilesModal: false,
    customerFromDashboard: null,

    filesFromDashboard: false,

    policyCreatedAt: null,

    showTerminationModal: false,
    policyFilesModalIsTermination: false,
    policyFilesModalIsPotential: false,
    showAddModelModal: false,
    addCompanyContactModal: false,
    addSaleCategoryModal: false,
    addSaleCategoryCompanyModal: false,
    showAddPotentialPolicyModal: false,
    policyIsConverting: false,
    convertingPolicyData: {
      agentId: "",
      text: "",
    },
    isAddingMember: false,
    contactToEdit: {},
    showSearchPotentialCustomerModal: false,
    isAddingPotentialCustomer: false,
    showPolicyProvisionModal: false,
    showAddAccidentModal: false,
    policyFilesModalIsAccident: false,
    policyFilesModalIsAccidentChildren: false,
    showAddInvoiceModal: false,
    showAddTransactionModal: false,
    showPayoutReviewModal: false,
    payoutReviewModalData: {},
    showResetPasswordModal: false,

    showTaxesModal: false,

    teamTaxIsEdit: false,
    showPolicyProvisionImportModal: false
  },
  getters: { 
    isDarkMode: (state) => state.isDarkMode,
    language: (state) => state.language,
    showAddCustomerModal: (state) => state.showAddCustomerModal,
    showAddFamilyMemberModal: (state) => state.showAddFamilyMemberModal,
    showAddSearchFamilyMemberModal: (state) => state.showAddSearchFamilyMemberModal,
    deleteModal: (state) => state.deleteModal,
    showAddServiceModal: (state) => state.showAddServiceModal,
    showAddPermissionModal: (state) => state.showAddPermissionModal,
    showAddRoleModal: (state) => state.showAddRoleModal,
    usersLoading: (state) => state.usersLoading,
    customersLoading: (state) => state.customersLoading,
    teamsLoading: (state) => state.teamsLoading,
    toast: (state) => state.toast,
    isAddingCustomer: (state) => state.isAddingCustomer,
    showAddCompanyModal: (state) => state.showAddCompanyModal,
    showAddPrivateModal: (state) => state.showAddPrivateModal,
    showEditPrivateEmailModal: (state) => state.showEditPrivateEmailModal,
    showEditCompanyEmailModal:(state) => state.showEditCompanyEmailModal,
    emailIdToEdit:(state) => state.emailIdToEdit,
    showAddUserModal: (state) => state.showAddUserModal,
    teamTaxesToEdit: (state) => state.teamTaxesToEdit,
    showAddTeamModal: (state) => state.showAddTeamModal,
    showAddTeamTaxModal: (state) => state.showAddTeamTaxModal,
    userToEdit: (state) => state.userToEdit,
    isEdit: (state) => state.isEdit,
    isProfileEdit: (state) => state.isProfileEdit,
    companyIsEdit: (state) => state.companyIsEdit,
    companyToEdit: (state) => state.companyToEdit,
    privateIsEdit: (state) => state.privateIsEdit,
    privateToEdit: (state) => state.privateToEdit,
    customerToAddMember: (state) => state.customerToAddMember,
    teamIsEdit: (state) => state.teamIsEdit,
    teamToEdit: (state) => state.teamToEdit,
    teamTaxIsEdit: (state) => state.teamTaxIsEdit,
    teamTaxToEdit: (state) => state.teamTaxToEdit,
    teamTaxesLoading: (state) => state.teamsLoading,
    showAddCustomerServicesModal: (state) => state.showAddCustomerServicesModal,
    showAddActivityModal:(state) => state.showAddActivityModal,
    showAddPendencyModal:(state) => state.showAddPendencyModal,
    
    showFilesEditModal: (state) => state.showFilesEditModal,
    filesEditMode: (state) => state.filesEditMode,
    filesToEdit: (state) => state.filesToEdit,
    filesToEditId: (state) => state.filesToEditId,

    pendencyForReply:(state) => state.pendencyForReply,
    isUserReplyingToPendency:(state) => state.isUserReplyingToPendency,
     

    pendencyReplyPolicyId: (state) => state.pendencyReplyPolicyId,
    pendencyReplyServiceId: (state) => state.pendencyReplyServiceId,
    pendencyReplyAgent: (state) => state.pendencyReplyAgent,

    pendencyEditData:(state) => state.pendencyEditData,



    replyEditData:(state) => state.replyEditData,
    replyEditPolicyId:(state) => state.replyEditPolicyId,

    isPendecyEditing: (state) => state.isPendecyEditing,
    isReplyEditing: (state) => state.isReplyEditing,

    showAddNotesModal:(state) => state.showAddNotesModal,
    showAddCallsModal:(state) => state.showAddCallsModal,

    isNoteEditing: (state) => state.isNoteEditing,
    editingNoteData: (state) => state.editingNoteData,

    isCallEditing: (state) => state.isCallEditing, 
    editingCallData: (state) => state.editingCallData,
   
    
    showAddPolicyModal: (state) => state.showAddPolicyModal,
    showPolicyFilesModal: (state) => state.showPolicyFilesModal,
    customerFromDashboard: (state) => state.customerFromDashboard,
    filesFromDashboard:(state) => state.filesFromDashboard,
    policyCreatedAt: (state) => state.policyCreatedAt,
    showTerminationModal: (state) => state.showTerminationModal,
    policyFilesModalIsTermination: (state) =>
      state.policyFilesModalIsTermination,
    showAddModelModal: (state) => state.showAddModelModal,
    addCompanyContactModal: (state) => state.addCompanyContactModal,
    showAddSaleCategoryModal: (state) => state.addSaleCategoryModal,
    showAddSaleCategoryCompanyModal: (state) => state.addSaleCategoryCompanyModal,
    showAddPotentialPolicyModal: (state) => state.showAddPotentialPolicyModal,
    policyFilesModalIsPotential: (state) => state.policyFilesModalIsPotential,
    policyIsConverting: (state) => state.policyIsConverting,
    convertingPolicyData: (state) => state.convertingPolicyData,
    isAddingMember: (state) => state.isAddingMember,
    contactToEdit: (state) => state.contactToEdit,
    showSearchPotentialCustomerModal: (state) =>
      state.showSearchPotentialCustomerModal,
    isAddingPotentialCustomer: (state) => state.isAddingPotentialCustomer,
    showPolicyProvisionModal: (state) => state.showPolicyProvisionModal,
    showAddAccidentModal: (state) => state.showAddAccidentModal,
    policyFilesModalIsAccident: (state) => state.policyFilesModalIsAccident,
    policyFilesModalIsAccidentChildren: (state) =>
      state.policyFilesModalIsAccidentChildren,
    showAddInvoiceModal: (state) => state.showAddInvoiceModal,
    showAddTransactionModal: (state) => state.showAddTransactionModal,
    showPayoutReviewModal: (state) => state.showPayoutReviewModal,
    payoutReviewModalData: (state) => state.payoutReviewModalData,
    showResetPasswordModal: (state) => state.showResetPasswordModal,
    showTaxesModal: (state) => state.showTaxesModal,
    showPolicyProvisionImportModal: (state) => state.showPolicyProvisionImportModal,
  },
  mutations: { 
    setIsDarkMode: (state, data) => {
      state.isDarkMode = data;
    },
    setLanguage: (state, data) => {
      state.language = data;
    },
    setShowAddCustomerModal: (state, data) => {
      state.showAddCustomerModal = data;
    },
    setShowAddFamilyMemberModal: (state, data) => {
      state.showAddFamilyMemberModal = data;
    },
    setShowAddSearchFamilyMemberModal: (state, data) => {
      state.showAddSearchFamilyMemberModal = data;
    },
    setDeleteModal: (state, data) => {
      state.deleteModal = data;
    },
    setShowAddServiceModal: (state, data) => {
      state.showAddServiceModal = data;
    },

    setShowAddPermissionModal: (state, data) => {
      state.showAddPermissionModal = data;
    },
    setShowAddRoleModal: (state, data) => {
      state.showAddRoleModal = data;
    },
    setUsersLoading: (state, data) => {
      state.usersLoading = data;
    },
    setTeamsLoading: (state, data) => {
      state.teamsLoading = data;
    },
    setTeamTaxesLoading: (state, data) => {
      state.teamTaxesLoading = data;
    },
    setCustomersLoading: (state, data) => {
      state.customersLoading = data;
    },
    setToast: (state, data) => {
      state.toast = data;
    },
    setIsAddingCustomer: (state, data) => {
      state.isAddingCustomer = data;
    },
    setShowAddCompanyModal: (state, data) => {
      state.showAddCompanyModal = data;
    },
    setShowAddPrivateModal: (state, data) => {
      state.showAddPrivateModal = data;
    },
    setshowEditPrivateEmailModal: (state, data)=>{
      state.showEditPrivateEmailModal = data;
    },
    setShowEditCompanyEmailModal: (state, data)=>{
      state.showEditCompanyEmailModal = data;
    },
    setEmailIdToEdit(state, data) {  
      state.emailIdToEdit = data;
    },
    setShowAddUserModal: (state, data) => {
      state.showAddUserModal = data;
    },
    setTeamTaxesToEdit: (state, data) =>{
      state.teamTaxesToEdit = data;
    },
    setShowAddTeamModal: (state, data) => {
      state.showAddTeamModal = data;
    },
    setShowAddTeamTaxModal: (state, data) => {
      state.showAddTeamTaxModal = data;
    },
    setUserToEdit: (state, data) => {
      state.userToEdit = data;
    },
    setIsEdit: (state, data) => {
      state.isEdit = data;
    },
    setIsProfileEdit: (state, data) => {
      state.isProfileEdit = data;
    },
    setCompanyIsEdit: (state, data) => {
      state.companyIsEdit = data;
    },
    setCompanyToEdit: (state, data) => {
      state.companyToEdit = data;
    },
    setPrivateIsEdit: (state, data) => {
      state.privateIsEdit = data;
    },
    setPrivateToEdit: (state, data) => {
      state.privateToEdit = data;
    },
    setCustomerToAddMember: (state, data) => {
      state.customerToAddMember = data;
    },
    setTeamIsEdit: (state, data) => {
      state.teamIsEdit = data;
    },
    setTeamToEdit: (state, data) => {
      state.teamToEdit = data;
    },
    setTeamTaxIsEdit: (state, data) => {
      state.teamTaxIsEdit = data;
    },
    setTeamTaxToEdit: (state, data) => {
      state.teamTaxToEdit = data;
    },
    setShowAddCustomerServicesModal: (state, data) => {
      state.showAddCustomerServicesModal = data;
    },
    setShowAddActivityModal:(state, data) =>{
      state.showAddActivityModal = data;
    },

    setShowAddPendencyModal:(state, data) =>{
      state.showAddPendencyModal = data;
    },

    setShowFilesEditModal: (state, data) => {
      state.showFilesEditModal = data;
    },

    setFilesEditMode: (state, data) => {
      state.filesEditMode = data;
    },

    setFilesToEdit: (state, data) => {
      state.filesToEdit = data;
    },

    setFilesToEditId: (state, data) => {
      state.filesToEditId = data;
    },

    setPendencyForReply: (state, data) =>{
      state.pendencyForReply = data;
    },
 

    setIsUserReplyingToPendency: (state, data) => {
      state.isUserReplyingToPendency = data;
    },

    setPendencyReplyData(state, { policyId, serviceId, agent }) {
      state.pendencyReplyPolicyId = policyId;
      state.pendencyReplyServiceId = serviceId;
      state.pendencyReplyAgent = agent;
    },

    setPendencyReplyAgentData(state,  agent ) {
      state.pendencyReplyAgent = agent;
    },

    setPendencyEditData:(state, data) =>{
      state.pendencyEditData = data;
    },

    setReplyEditData:(state, {reply, policyId}) =>{
      state.replyEditData = reply;
      state.replyEditPolicyId = policyId;
    },

    setIsPendecyEditing: (state, data) =>{
      state.isPendecyEditing = data;
    },

    setIsReplyEditing: (state, data) =>{
      state.isReplyEditing = data;
    },

    setShowAddNotesModal:(state, data) =>{
      state.showAddNotesModal = data;
    },

    setShowAddCallsModal:(state, data) =>{
      state.showAddCallsModal = data;
    },

    setIsCallEditing: (state, data) =>{
      state.isCallEditing = data;
    }, 

    setEditingCallData: (state, data) =>{
      state.editingCallData = data;
    },


    setIsNoteEditing: (state, data) =>{
      state.isNoteEditing = data;
    },

    setEditingNoteData: (state, data) => {
      state.editingNoteData = data;
    },


    setShowAddPolicyModal: (state, data) => {
      state.showAddPolicyModal = data;
    },

    setShowPolicyFilesModal: (state, data) => {
      state.showPolicyFilesModal = data;
    },

    setCustomerFromDashboard: (state, data) => {
      state.customerFromDashboard = data;
    },

 

    setFilesFromDashboard: (state, data) => {
      state.filesFromDashboard = data;
    },

    setPolicyCreatedAt: (state, data) =>{
      state.policyCreatedAt = data;
    },

    setShowTerminationModal: (state, data) => {
      state.showTerminationModal = data;
    },

    setPolicyFilesModalIsTermination: (state, data) => {
      state.policyFilesModalIsTermination = data;
    },
    setPolicyFilesModalIsPotential: (state, data) => {
      state.policyFilesModalIsPotential = data;
    },
    setShowAddModelModal: (state, data) => {
      state.showAddModelModal = data;
    },
    setShowAddCompanyContactModal: (state, data) => {
      state.addCompanyContactModal = data;
    },
    setShowAddSaleCategoryModal: (state, data) => {
      state.addSaleCategoryModal = data;
    },
    setShowAddSaleCategoryCompanyModal: (state, data) => {
      state.addSaleCategoryCompanyModal = data;
    },
    setShowAddPotentialPolicyModal: (state, data) => {
      state.showAddPotentialPolicyModal = data;
    },
    setPolicyIsConverting: (state, data) => {
      state.policyIsConverting = data;
    },
    setConvertingPolicyData: (state, data) => {
      state.convertingPolicyData = data;
    },
    setIsAddingMember: (state, data) => {
      state.isAddingMember = data;
    },
    setContactToEdit: (state, data) => {
      state.contactToEdit = data;
    },
    setShowSearchPotentialCustomerModal: (state, data) => {
      state.showSearchPotentialCustomerModal = data;
    },
    setIsAddingPotentialCustomer: (state, data) => {
      state.isAddingPotentialCustomer = data;
    },
    setShowPolicyProvisionModal: (state, data) => {
      state.showPolicyProvisionModal = data;
    },
    setShowAddAccidentModal: (state, data) => {
      state.showAddAccidentModal = data;
    },
    setPolicyFilesModalIsAccident: (state, data) => {
      state.policyFilesModalIsAccident = data;
    },
    setPolicyFilesModalIsAccidentChildren: (state, data) => {
      state.policyFilesModalIsAccidentChildren = data;
    },
    setShowAddInvoiceModal: (state, data) => {
      state.showAddInvoiceModal = data;
    },
    setShowAddTransactionModal: (state, data) => {
      state.showAddTransactionModal = data;
    },
    setShowPayoutReviewModal: (state, data) => {
      state.showPayoutReviewModal = data;
    },
    setPayoutReviewModalData: (state, data) => {
      state.payoutReviewModalData = data;
    },
    setShowResetPasswordModal: (state, data) => {
      state.showResetPasswordModal = data;
    },
    setShowTaxesModal:(state, data) => {
      state.showTaxesModal = data;
    },
    setShowPolicyProvisionImportModal: (state, data) => {
      state.showPolicyProvisionImportModal = data;
    },
  },
  actions: {},
};
