import api from "@/api";

export default {
  state: {
    userForms: {},
    customerForms: {},
    policyForms: {},
    terminationForms: {},
    companyForms: {},
    potentialPolicyForms: {},
    reportForms: {},
    teamForms: {},
    teamTaxesForms: {},

    pendencyForms:{},
    customerPolicies:{},

    notesForms:{},
    callsForms: {},
  },
  getters: {
    userForms: (state) => state.userForms,
    customerForms: (state) => state.customerForms,
    policyForms: (state) => state.policyForms,
    terminationForms: (state) => state.terminationForms,
    companyForms: (state) => state.companyForms,
    potentialPolicyForms: (state) => state.potentialPolicyForms,
    reportForms: (state) => state.reportForms,
    teamForms: (state) => state.teamForms,
    teamTaxesForms: (state) => state.teamTaxesForms,

    pendencyForms: (state) => state.pendencyForms,
    customerPolicies: (state) => state.customerPolicies,

    notesForms: (state) => state.notesForms,

    callsForms: (state) => state.callsForms,
  },
  mutations: {
    setUserForms: (state, data) => {
      state.userForms = data;
    },
    setTeamForms: (state, data) => {
      state.teamForms = data;
    },
    setCustomerForms: (state, data) => {
      state.customerForms = data;
    },
    setPolicyForms: (state, data) => {
      state.policyForms = data;
    },
    setTerminationForms: (state, data) => {
      state.terminationForms = data;
    },
    setCompanyForms: (state, data) => {
      state.companyForms = data;
    },
    setPotentialPolicyForms: (state, data) => {
      state.potentialPolicyForms = data;
    },
    setReportForms: (state, data) => {
      state.reportForms = data;
    },
    setTeamTaxesForms: (state, data) => {
      state.teamTaxesForms  = data;
    },

    setPendencyForms: (state, data) => {
      state.pendencyForms = data;
    },
    
    setNotesForms: (state, data) => {
      state.notesForms = data;
    },

    setCallsForms: (state, data) => {
      state.callsForms = data;
    },

    setCustomerPolicies: (state, data) => {
      state.customerPolicies = data
    }
  },
  actions: {
    getUserForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/user-forms"
        );
        const { data } = response.data;
        commit("setUserForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getUserFormsEdit: async ({ commit }, teams) => {
      try {
        const response = await api({ requiresAuth: true }).post(
          "/v1/partials/user-forms-team", { 
            teams: teams ,
          }
        );
        const { data } = response.data;
        commit("setUserForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getTeamForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
            "/v1/partials/team-forms"
        );
        const { data } = response.data;
        commit("setTeamForms", data);
      } catch (err) {
        console.error(err);
      }
    },
    getTeamTaxesForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
            "/v1/partials/team-tax-forms"
        );
        const { data } = response.data;
        commit("setTeamTaxesForms", data);
      } catch (err) {
        console.error(err);
      }
    },
    getCustomerForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/customer-forms"
        );
        const { data } = response.data;
        commit("setCustomerForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getPolicyForms: async ({ commit }, team_id) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/policy-forms", {
            params: { team_id },
          }
        );
        const { data } = response.data;
        commit("setPolicyForms", data);
      } catch (err) {
        console.error(err);
      }
    }, 

    getCompanyForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/company-forms"
        );
        const { data } = response.data;
        commit("setCompanyForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getPotentialPolicyForms: async ({ commit }, team_id) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/potential-policy-forms", {
            params: { team_id },
          }
        );
        const { data } = response.data;
        commit("setPotentialPolicyForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    

    getReportForms: async ({ commit }, team_id) => {
      try {
        const {
          data: { data },
        } = await api({ requiresAuth: true }).get(`/v1/partials/report-forms`, {
          params: { team_id },
        });
        commit("setReportForms", data);
      } catch (err) {
        console.error(err);
      }
    },
    

    
    getPendencyForms: async ({commit}) => {
      try {
        const {
          data: { data },
        } = await api({ requiresAuth: true }).get("/v1/partials/pendency-forms");
        commit("setPendencyForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getCustomerPolicies: async ({commit}, id) => {
      try {
        const {
          data: { data },
        } = await api({ requiresAuth: true }).get(`/v1/partials/customer-policies/${id}`);
        commit("setCustomerPolicies", data);
      } catch (err) {
        console.error(err);
      }
    },


    getNotesForms: async({commit}) => {
      try{
        const {
          data: {data},
        } = await api({requiresAuth: true}).get("/v1/partials/note-forms");
        commit("setNotesForms", data);
      }catch(err){
        console.log(err)
        return null;
      }
    },


    getCallsForms: async({commit}) => {
      try{
        const {
          data: {data},
        } = await api({requiresAuth: true}).get("/v1/partials/call-forms");
        commit("setCallsForms", data);
      }catch(err){
        console.log(err)
        return null;
      }
    }
  },
};
